<template>
	<div class="info-container">
		<div class="entity">
			<div class="label">头像</div>
			<div class="value">
				<!-- <el-avatar :key="avatorHref" :src="avatorHref"></el-avatar> -->
				<el-upload
					class="avatar-uploader"
					action="https://jsonplaceholder.typicode.com/posts/"
					:show-file-list="false"
					:on-success="handleAvatarSuccess"
					:on-change="getFile"
					:before-upload="beforeAvatarUpload"
				>
					<img v-if="imageUrl" :src="imageUrl" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
		</div>
		<div class="entity">
			<div class="label">名称</div>
			<div class="value">
				<el-input v-model="name" maxlength="50" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class="entity">
			<div class="label">性别</div>
			<div class="value">
				<el-radio-group v-model="sex">
					<el-radio :label="0">男</el-radio>
					<el-radio :label="1">女</el-radio>
				</el-radio-group>
			</div>
		</div>
		<!-- <div class="entity">
			<div class="label">密码</div>
			<div class="value">
				<el-input
					type="password"
					v-model="password"
					placeholder="请输入内容"
				></el-input>
			</div>
		</div> -->
		<div class="entity">
			<div class="label">手机号</div>
			<div class="value">
				<el-input v-model="phone" maxlength="11" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class="entity">
			<div class="label">职称</div>
			<div class="value">
				<el-input v-model="title" maxlength="50" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class="entity">
			<div class="label">所属学院</div>
			<div class="value">
				<el-input v-model="university" maxlength="50" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class="entity">
			<div class="label">所属单位</div>
			<div class="value">
				<el-input v-model="institution" maxlength="50" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div class="entity">
			<div class="label"></div>
			<div class="value">
				<el-button type="primary" class="btn" @click="updateinfo()"
					>保存</el-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { UserCenterApi, ApiClient } from "../../../api"
import Swal from "sweetalert2"
export default {
	data() {
		return {
			// avator: "",
			avatorImage: false,
			name: "",
			sex: 0,
			password: "",
			phone: "",
			title: "",
			university: "",
			institution: "",
			imageUrl: "",
			img: "",
			api: new UserCenterApi(ApiClient.instance)
		}
	},
	mounted() {
		this.getinfo()
	},
	methods: {
		handleAvatarSuccess(res, file) {
			this.imageUrl = URL.createObjectURL(file.raw)
		},
		getBase64(file) {
			return new Promise(function (resolve, reject) {
				const reader = new FileReader()
				let imgResult = ""
				reader.readAsDataURL(file)
				reader.onload = function () {
					imgResult = reader.result
				}
				reader.onerror = function (error) {
					reject(error)
				}
				reader.onloadend = function () {
					resolve(imgResult)
				}
			})
		},
		getFile(file, fileList) {
			this.getBase64(file.raw).then((res) => {
				this.img = res.split(",")[1]
			})
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === "image/jpeg" || "image/png" || "image/jpg"
			const isLt2M = file.size / 1024 / 1024 < 1
			if (!isJPG) {
				this.$message.error("上传头像图片只能是 JPG,PNG,JPEG 格式!")
			}
			if (!isLt2M) {
				this.$message.error("上传头像图片大小不能超过 1M!")
			}
			return isJPG && isLt2M
		},
		getinfo() {
			this.api.apiUserCenterInfoGet((e, data, resp) => {
				if (data.success) {
					this.img = data.result.avator
					this.imageUrl = "data:image/png;base64," + data.result.avator
					this.name = data.result.name
					this.sex = data.result.sex
					this.password = data.result.password
					this.phone = data.result.phone
					this.title = data.result.title
					this.university = data.result.university
					this.institution = data.result.institution
					// localStorage.setItem("avator", JSON.stringify(data.result.avator))
				}
			})
		},
		updateinfo() {
			if (!this.name) {
				this.$message({ message: "名称不能为空！", type: "error" })
				return
			}
			if (this.name.length > 50) {
				this.$message({ message: "名称长度输入不正确", type: "error" })
				return
			}
			if (!this.phone) {
				this.$message({ message: "手机号不能为空！", type: "error" })
				return
			}
			var phoneRe = /^1\d{10}$/
			if (!phoneRe.test(this.phone)) {
				this.$message({ message: "手机号格式输入错误！", type: "error" })
				return
			}
			if (!this.title) {
				this.$message({ message: "职称不能为空！", type: "error" })
				return
			}
			if (this.title.length > 50) {
				this.$message({ message: "职称长度输入不正确", type: "error" })
				return
			}
			if (!this.university) {
				this.$message({ message: "所属学院不能为空！", type: "error" })
				return
			}
			if (this.university.length > 50) {
				this.$message({ message: "所属学院长度输入不正确", type: "error" })
				return
			}
			if (!this.institution) {
				this.$message({ message: "所属单位不能为空！", type: "error" })
				return
			}
			if (this.institution.length > 50) {
				this.$message({ message: "所属单位长度输入不正确", type: "error" })
				return
			}
			this.api.apiUserCenterInfoPost(
				{
					avator: this.img,
					name: this.name,
					sex: this.sex,
					// password: this.password,
					phone: this.phone,
					title: this.title,
					university: this.university,
					institution: this.institution
				},
				(e, data, resp) => {
					if (data.success) {
						localStorage.setItem("avator", JSON.stringify(this.img))
						Swal.fire("保存成功").then(() => window.location.reload())
					}
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
/deep/.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	img {
		width: 45px;
		height: 45px;
	}
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
}
.avatar {
	width: 50px;
	height: 50px;
	display: block;
}
.info-container {
	width: 860px;
	height: 692px;
	background: #ffffff;
	box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
	padding: 60px 70px;
	.entity {
		display: flex;
		width: 470px;
		height: 40px;
		margin-bottom: 20px;
		.label {
			display: flex;

			width: 70px;
			.c-font-size-14();
			.c-font-family-PF();
			font-weight: 500;
			color: #333333;

			margin-right: 30px;
			align-items: center;
			justify-content: flex-end;
		}
		.value {
			width: 400px;
		}
		.btn {
			width: 220px;
		}
	}
}
</style>
