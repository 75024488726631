var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-container" }, [
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("头像")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "https://jsonplaceholder.typicode.com/posts/",
                "show-file-list": false,
                "on-success": _vm.handleAvatarSuccess,
                "on-change": _vm.getFile,
                "before-upload": _vm.beforeAvatarUpload,
              },
            },
            [
              _vm.imageUrl
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.imageUrl },
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("名称")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c("el-input", {
            attrs: { maxlength: "50", placeholder: "请输入内容" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("性别")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.sex,
                callback: function ($$v) {
                  _vm.sex = $$v
                },
                expression: "sex",
              },
            },
            [
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("男")]),
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("女")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("手机号")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c("el-input", {
            attrs: { maxlength: "11", placeholder: "请输入内容" },
            model: {
              value: _vm.phone,
              callback: function ($$v) {
                _vm.phone = $$v
              },
              expression: "phone",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("职称")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c("el-input", {
            attrs: { maxlength: "50", placeholder: "请输入内容" },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("所属学院")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c("el-input", {
            attrs: { maxlength: "50", placeholder: "请输入内容" },
            model: {
              value: _vm.university,
              callback: function ($$v) {
                _vm.university = $$v
              },
              expression: "university",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }, [_vm._v("所属单位")]),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c("el-input", {
            attrs: { maxlength: "50", placeholder: "请输入内容" },
            model: {
              value: _vm.institution,
              callback: function ($$v) {
                _vm.institution = $$v
              },
              expression: "institution",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "entity" }, [
      _c("div", { staticClass: "label" }),
      _c(
        "div",
        { staticClass: "value" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.updateinfo()
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }